import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {
  authEmailVar,
  selectedAuthTypeVar,
} from '../../graphql/reactive-variables';
import useAuthTypeFromParams from '../../hooks/useAuthTypeFromParams';
import { SIGN_IN, SIGN_UP } from '../../constants/auth';

const AuthTabs = () => {
  const authType = useAuthTypeFromParams();
  const selectedAuthType = useReactiveVar(selectedAuthTypeVar);

  useEffect(() => {
    selectedAuthTypeVar(authType);
  }, [authType]);

  return (
    <Tabs
      aria-label="Auth Tabs"
      value={selectedAuthType}
      onChange={(_, newAuthType) => {
        selectedAuthTypeVar(newAuthType);
        authEmailVar('');
      }}
      TabIndicatorProps={{
        style: { display: 'none' },
      }}
      sx={{
        minHeight: '30px',
        height: '30px',
        overflow: 'visible',
        position: 'relative',
        '& .MuiTabs-flexContainer': {
          '& button': {
            fontSize: '13px',
            borderRadius: 10,
            backgroundColor: '#F2F2F2',
            minHeight: '30px',
            height: '30px',
            width: 150,
            zIndex: 1,
            transition: 'transform 0.4s, margin 0.4s',
          },
          '& button:first-of-type': {
            transform:
              selectedAuthType === 'signIn'
                ? 'translateX(10%)'
                : 'translateX(0)',
            marginRight: selectedAuthType === 'signIn' ? '0' : '10px',
          },
          '& button:last-of-type': {
            transform:
              selectedAuthType === 'signUp'
                ? 'translateX(-10%)'
                : 'translateX(0)',
            marginLeft: selectedAuthType === 'signUp' ? '-20px' : '0',
          },
        },
        '& button:hover': {
          backgroundColor: 'primary.light',
        },
        '& button.Mui-selected': {
          backgroundColor: 'primary.main',
          color: 'primary.light',
          zIndex: 2,
        },
      }}
      centered
    >
      <Tab label="INGRESAR" value={SIGN_IN} aria-label="SignIn Tab" />
      <Tab label="CREAR CUENTA" value={SIGN_UP} aria-label="SignUp Tab" />
    </Tabs>
  );
};

export default AuthTabs;
