import React, { useCallback } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import AccessTime from '@mui/icons-material/AccessTime';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Warning from '@mui/icons-material/Warning';
import moment from 'moment';

const useStyles = makeStyles({
  stepper: {
    padding: 0,
  },
  stepLabel: {
    marginTop: '4px !important',
    fontSize: 12,
  },
  collectionActionsButton: {
    fontSize: 12,
    height: 0,
  },
});

const InvoiceStatusStepper = (
  { orientation, invoice: { siiStatus, receptionDate, hasExecutiveMerit, daysToExecutiveMerit } },
) => {
  const classes = useStyles();
  const icons = {
    processing: <AccessTime color="warning" />,
    error: <ErrorOutline color="error" />,
    accepted: <CheckCircleOutline color="success" />,
    warning: <Warning color="warning" />,
  };
  const validLabel = useCallback(() => {
    if (hasExecutiveMerit) {
      return 'Con mérito';
    }
    return `Quedan ${daysToExecutiveMerit} días`;
  }, [receptionDate]);
  const validIcon = useCallback(() => {
    if (hasExecutiveMerit) {
      return icons.warning;
    }
    return icons.processing;
  }, [receptionDate, icons]);
  const siiStatusLabels = {
    PROCESSING: { label: '', icon: icons.processing },
    REJECTED_BY_SII: { label: 'Rechazado', icon: icons.error },
    REJECTED_RECEIVER: { label: 'Rechazado', icon: icons.error },
    REJECTED_CONTENT: { label: 'Reclamos al contenido', icon: icons.error },
    REJECTED_TOTAL: { label: 'Reclamos por falta total', icon: icons.error },
    REJECTED_PARTIAL: { label: 'Reclamos por falta parcial', icon: icons.error },
    VALID: { label: validLabel(), icon: validIcon() },
    RECEPTION_ACKNOWLEDGED: { label: 'Con acuse', icon: icons.accepted },
    PARTIAL_CLAIM: { label: 'Con rechazo por falta parcial de mercaderías', icon: icons.error },
    TOTAL_CLAIM: { label: 'Con rechazo por falta total de mercaderías', icon: icons.error },
    ALREADY_PAYED: { label: 'Al contado', icon: icons.accepted },
    INVALID_STATUS: { label: validLabel(), icon: validIcon() },
    UNKNOWN_STATUS: { label: validLabel(), icon: validIcon() },
  };
  const { label, icon } = siiStatusLabels[siiStatus];
  return (
    <Stepper
      alternativeLabel={orientation === 'horizontal'}
      className={classes.stepper}
      orientation={orientation}
    >
      <Step completed key={label}>
        <StepLabel
          icon={icon}
          classes={{
            label: classes.stepLabel,
          }}
        >
          {label}
        </StepLabel>
      </Step>
    </Stepper>
  );
};

InvoiceStatusStepper.propTypes = {
  invoice: PropTypes.shape({
    siiStatus: PropTypes.string.isRequired,
    receptionDate: PropTypes.objectOf(moment).isRequired,
    hasExecutiveMerit: PropTypes.bool.isRequired,
    daysToExecutiveMerit: PropTypes.number.isRequired,
  }).isRequired,
  orientation: PropTypes.string,
};
InvoiceStatusStepper.defaultProps = {
  orientation: 'horizontal',
};

export default InvoiceStatusStepper;
