import React, { useCallback } from 'react';
import { ResponsiveContainer, BarChart, Bar, Tooltip, Legend, XAxis, YAxis, CartesianGrid } from 'recharts';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import makeStyles from '@mui/styles/makeStyles';
import { formatMoney, formatMillion } from '@fingo/lib/helpers';

const useStyles = makeStyles(() => ({
  card: {
    paddingTop: '2%',
    paddingBottom: '2%',
    paddingLeft: '4%',
    paddingRight: '4%',
    boxShadow: '0px 0px 12px #00000029',
    borderRadius: '8px',
  },
}
));

const BaseBarGraph = ({ orderedData, beforeName, actualName, title }) => {
  const theme = useTheme();
  const classes = useStyles();
  const chartYAxisWidth = useCallback((values) => {
    const numbers = values.map((value) => value[beforeName]);
    const newNumbers = values.map((value) => value[actualName]);
    const maxValue = Math.max(...numbers, ...newNumbers);
    const numCharacters = formatMillion(maxValue).length;
    return Math.max(numCharacters, 5) * 9;
  }, [beforeName, actualName]);
  return (
    <Card className={classes.card}>
      <Grid container justifyContent="flex-start" alignItems="center">
        <Grid item sx={{ ml: 15 }}>
          <Typography variant="h2" color="primary.main">{title}</Typography>
        </Grid>
      </Grid>
      <ResponsiveContainer height={270} width="100%">
        <BarChart data={orderedData}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            angle={-45}
            textAnchor="end"
            interval={0}
            tick={theme.typography.body2}
          />
          <YAxis
            tickFormatter={(tick) => formatMillion(tick)}
            tick={theme.typography.body2}
            width={chartYAxisWidth(orderedData)}
          />
          <Tooltip
            formatter={(value) => `$ ${formatMoney(value)}`}
            cursor={{ fill: theme.palette.chart.lightPinkColor }}
            labelStyle={theme.typography.body2}
            contentStyle={theme.typography.body2}
          />
          <Legend
            verticalAlign="top"
            align="right"
            formatter={(value, entry) => (
              <Typography variant="body2" color={entry.color} component="span">{value}</Typography>
            )}
          />
          <Bar dataKey={beforeName} fill={theme.palette.chart.secondary} />
          <Bar dataKey={actualName} fill={theme.palette.chart.primary} />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

BaseBarGraph.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  orderedData: PropTypes.array.isRequired,
  beforeName: PropTypes.string.isRequired,
  actualName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default BaseBarGraph;
